// Import mixins
@use 'assets/styles/mixins.scss'  as * ;

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN SPIN */

.ant-spin-blur {
  filter: none !important;
}

.ant-spin-nested-loading > div > .ant-spin {
  max-height: none;
}
