@use 'assets/styles/mixins.scss'  as * ;

/////////////////////////////////////////////////////////////////////////////////////////
/* CAROUSEL */

.carousel-indicators {
  li {
    background: $gray-lighten;
    opacity: 0.5;
    &.active {
      opacity: 1;
      background: $gray-lighten;
    }
  }
}
