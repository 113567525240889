@use 'assets/styles/mixins.scss' as *;

// File to override default values from frameworks

.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title,
.ant-form,
.ant-form label,
.ant-btn {
  font-size: 14px !important;
}

.ant-input {
  height: 32px !important;
}

body {
  background-color: white;
  font-size: 14px !important;

  .ant-layout {
    min-height: 94vh;
  }
}

.settings {
  &__menuShadow {
    .ant-layout-sider {
      box-shadow: 0 0 40px -20px rgba(57, 55, 73, 0.4);
    }
  }
}
.ant-carousel,
.slick-slider.slick-initialized {
  height: 100% !important;
}
.slick-dots.slick-dots-bottom li button {
  background: $gray-darken !important;
}

.ant-carousel .slick-dots li.slick-active button {
  background: $primary !important;
}

.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner-full {
  margin-top: -0.5em !important;
}

.ant-table-thead > tr > th {
  font-weight: 600 !important;
}

.ant-legacy-form-item-control {
  line-height: 1.5715 !important;
}

.ant-form-inline .ant-form-item-with-help {
  margin-bottom: 0 !important;
}

.ant-select-selection__placeholder,
.ant-input-prefix i {
  color: $gray-darker !important;
}

input::-webkit-input-placeholder {
  color: $gray-darker !important;
}
input::-moz-placeholder {
  color: $gray-darker !important;
}
input:-moz-placeholder {
  color: $gray-darker !important;
}
input:-ms-input-placeholder {
  color: $gray-darker !important;
}

.ant-tabs-nav-container {
  font-size: 14px !important;
}

.ant-form-item-control {
  line-height: inherit !important;
}
.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  margin-right: 0;
}
.ant-menu-dark .ant-menu-item-disabled,
.ant-menu-dark .ant-menu-submenu-disabled,
.ant-menu-dark .ant-menu-item-disabled > a,
.ant-menu-dark .ant-menu-submenu-disabled > a {
  opacity: 1 !important;
}

.ant-input-affix-wrapper {
  padding: 0 7px !important;
}

// Privacy
.privacy-content {
  display: flex;
  background-color: white;

  .drawer {
    z-index: 2;
  }

  .content {
    padding: 20px 60px;
    max-height: 150vh;
    overflow-y: scroll;
    width: 100%;
  }

  h1 {
    font: 600 18px/21px 'Open Sans', sans-serif;
    margin: 0 0 10px;
    color: #0190fe;
    text-transform: uppercase;
  }

  h2 {
    font: 400 18px/21px 'Open Sans', sans-serif;
    margin: 0 0 10px;
    color: #0190fe;
  }

  h3 {
    font: 600 14px/21px 'Open Sans', sans-serif;
    margin: 0 0 10px;
    color: #14171a;
  }

  @media (max-width: 767px) {
    .ant-menu {
      height: 100%;
      padding-top: 102px;
    }

    .content {
      padding: 20px;
      margin-bottom: 30px;
    }
  }
}
// End privacy

// stylelint-disable-next-line
.ant-carousel .slick-dots li.slick-active button {
  background: green !important;
}

#assign-modal .ant-form-explain {
  color: red;
}

#setup-quote .ant-collapse-header,
#goLiveModal .ant-collapse-header {
  background-color: #e8e8e8;
}

.ant-tooltip-inner {
  white-space: pre-line;
}

// loading icon when searching app under snapshot
.qualify-app-search .anticon.anticon-loading {
  color: red;
}

// floating quotes button
.floatingQuoteButton {
  position: absolute;
  bottom: 15%;
  right: 39px;
  button {
    box-shadow: 0 17px 10px -10px rgba(0, 0, 0, 0.4) !important;
    min-height: 60px;
    min-width: 60px;
    span {
      font-size: 22px;
    }
  }
}

strong {
  font-weight: bold;
}
