@use 'assets/styles/mixins.scss'  as * ;

.authenticated {
  position: absolute;
  top: 1px;
  right: 70px;
}

.notAuthenticated {
  span {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.dropdown {
  padding-right: 5px;
  cursor: pointer;

  &:hover {
    color: $blue;

    &::after {
      color: $gray-darken;
    }
  }
}

.avatar {
  background-color: transparent;
}

.menuIcon {
  width: 20px;
  margin-right: rem(20);
}
.languageSelected {
  background-color: rgb(172, 215, 255);
  min-width: 100px;
}
.homePage {
  top: 15px;
}

.dashboard {
  right: 125px;
}

.lnglocale {
  display: flex;
}
